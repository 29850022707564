import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NoMatch = () => (
  <>
  <Container className="text-center container--spacing-page">
    <Row>
      <Col>
       <Image />
       <h1>Sorry, something went wrong</h1>
       <p>The apge you are looking for might have been removed or is temporarily unavailable.</p>
      <p><Link to="/">Go to the homepage</Link></p>
      </Col>
    </Row>
  </Container>
  </>
);

export default NoMatch;
