import React from 'react';
import { ExternalLink } from 'react-external-link';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';
import { Title, History } from '../../components';
import { BsArrowUpRight, BsArrowDownCircle } from 'react-icons/bs';


const About = () => (
  <div>

<Container className="container--margin">
<Row><Col><h1 className="text-center">ABOUT</h1></Col></Row>
<Row>
  <Col xs={12} s={12} md={3} lg={3}>
  <Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/b0d045b2-7133-4b6c-b33f-821dc5a02100/donvvoCaseStudiesHeader" fluid />
  </Col>
  <Col xs={12} s={12} md={9} lg={9}>
   <Row>
     <Col><h4>Name</h4></Col>
     <Col><p>Don Vo</p></Col>
   </Row>
   <Row>
     <Col><h4>Description</h4></Col>
     <Col><p>Brought on to lead new website creation and brand development for publicly traded client, Crown Capital (CRWN), while creating agile environment with young design and development team.</p></Col>
   </Row>
   <Row>
    <Col><h4>Current Location</h4></Col>
     <Col>
     
      <p>Toronto, Canada</p>
     </Col>
   </Row>
   <Row>
    <Col><h4>Resume</h4></Col>
     <Col>
     
      <p>PDF <BsArrowUpRight /></p>
      <p>Word Format <BsArrowDownCircle /></p>
     </Col>
   </Row>
</Col>
</Row>

<Row>
<Col><hr /></Col>
</Row>

<Row>
 
  <Col><h2 className="text-center">Projects</h2></Col>
</Row>

  <Row xs={1} s={2} md={3} lg={3}>
  
  <History company="Crown Capital" year="2020" linkUrl="https://crowncapital.ca/" text="A publicly traded specialty finance company focused on providing capital to middle-market companies." badge="Lead UI/UX Designer" />
  <History company="Allegory" year="2019" linkUrl="https://allegoryapp.com/" text="Insurance technology company that leverages data and machine learning to reduce risk, and provide better rates." badge="CTO & UI/UX Designer" />  
  <History company="Chelle Coin" year="2017" linkUrl="https://www.chellecoin.com" text="Ethereum based cryptocurrency backed by real estate." badge="Lead UI/UX Designer" />
  <History company="Aqre App" year="2017" url="https://www.aqre.app" text="Real estate cryptocurrency platform." badge="Lead UI/UX Designer" />
  <History company="Aqre Home" year="2017" linkUrl="https://www.aqrehome.com" text="Real estate buy and sell platform that specializes in offering crypto currency transactions." badge="Lead UI/UX Designer" />
  <History company="BMO" year="2015" url="https://www.bmo.com/" text="Worked with development team on large website rebrand and launch in 2015." badge="Front-End Developer" />
  <History company="BMO Harris" year="2015"linkUrl="https://www.bmoharris.com/main/personal" text="An American bank based in Chicago, Illinois. It is a member of the Federal Reserve System." badge="Front-End Developer" />
  <History company="Diners Club International" year="2015" linkUrl="https://www.dinersclubcanada.com/home" text="A charge card company owned by Discover Financial Services." badge="Front-End Developer" />
  <History company="J Crew" year="2014" linkUrl="https://www.jcrew.com" text="Classic American online clothing retailer." badge="UI/UX Designer" />
  <History company="Questr" year="2014" linkUrl="https://questr.co/" text="On demand delivery application for anything and more." badge="CTO & Designer" />
</Row>



<Row>
<Col><hr /></Col>
</Row>

<Row>
    <Col>
    <h2>Education</h2>
    </Col>
  </Row>
<Row>
    <Col>
    <p>University of Western Ontario, BSc Neuroscience, Minor in Computer Science</p>
      
    </Col>
    <Col>
      <p className="text-right">2014</p>
    </Col>
  </Row>
  <Row>
    <Col>
      <p>Rise EnterpRISEing Youth+ Graduate</p>
    </Col>
    <Col>
      <p className="text-right">2021</p>
    </Col>
  </Row>

  <Row>
<Col><hr /></Col>
</Row>
  <Row>
    <Col><h2>Experience</h2></Col>
  </Row>
  <Row>
   <Col>
    <p>Wireframing</p>
   </Col>
   <Col>
    <p className="text-right">10 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Front-End Development</p>
   </Col>
   <Col>
    <p className="text-right">10 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Storyboarding</p>
   </Col>
   <Col>
    <p className="text-right">10 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
    <p>Rapid Prototyping</p>
   </Col>
   <Col>
    <p className="text-right">10 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Design Sprints</p>
   </Col>
   <Col>
    <p className="text-right">9 years</p>
   </Col>
 </Row>
 
 
 <Row>
   <Col>
   <p>User Research</p>
   </Col>
   <Col>
    <p className="text-right">7 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Usability Testing</p>
   </Col>
   <Col>
    <p className="text-right">7 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
    <p>Branding</p>
   </Col>
   <Col>
    <p className="text-right">7 years</p>
   </Col>
 </Row>

 <Row>
<Col><hr /></Col>
</Row>
 <Row>
   <Col>
   <h2>Tools</h2>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Adobe Creative Suite</p>
   </Col>
   <Col>
    <p className="text-right">15 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Sketch</p>
   </Col>
   <Col>
    <p className="text-right">10 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Keynote</p>
   </Col>
   <Col>
    <p className="text-right">10 years</p>
   </Col>
 </Row> 
 <Row>
   <Col>
   <p>Invision</p>
   </Col>
   <Col>
    <p className="text-right">9 years</p>
   </Col>
 </Row>
 <Row>
   <Col>
   <p>Figma</p>
   </Col>
   <Col>
    <p className="text-right">5 years</p>
   </Col>
 </Row>

 <Row>
   <Col>
   <p>InVision Studio</p>
   </Col>
   <Col>
    <p className="text-right">3 years</p>
   </Col>
 </Row>
 

</Container>


  </div>
);

export default About;



