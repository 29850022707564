import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Card, ListGroup, Row, Badge, Button } from 'react-bootstrap';
import { ExternalLink } from 'react-external-link';
import './history.css';

const History = ({year, company, linkUrl, text, badge}) => (
<>
<Col className="history">
  <h4>{ company }</h4>
  <p>{ badge }</p>
  <p>{ text }</p>
  <ExternalLink href={linkUrl} className="link--primary"><Button>Visit Site</Button></ExternalLink>
</Col>

</>

);

export default History;
