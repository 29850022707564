import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const TimHortons = () => (
  <>
  <Container>
    <Row>
      <Col align="center">
        <h1>Tim Hortons Business Card Design</h1>
        <p>
        This is a business card design that is used for Tim Hortons owners, or food groups associated with Tim Hortons.
        </p>
      </Col>
    </Row>
    <Row>
      <Col><Image /></Col>
      <Col><Image /></Col>
    </Row>
    </Container>
  </>
);

export default TimHortons;



