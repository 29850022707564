import React from 'react';
import { Routes, Route, Outlet, Link } from 'react-router-dom';

import { Button, Badge, Container, Row, Col, Tabs, Tab, Image } from 'react-bootstrap';
import { MyNav, Footer, WorkItem, History, HistoryLead, HistoryCofounder, CaseStudy, Companylogos } from './components';
import { NoMatch, Faq, Contact, Termsofuse, Privacypolicy, About, TimHortons, PubGuru, ThomsonReuters, CrownCapital, SemiPolished, ApexHealthNetwork, UrCBD, LandWeLove, Philosockphy, UrTHC, MediaDonna, TakeMyDollar, PivotDigital } from './pages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



export default function App() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tim-hortons" element={<TimHortons />} />
          <Route path="/crown-capital" element={<CrownCapital />} />
          <Route path="/apex-health-network" element={<ApexHealthNetwork />} />
          
          <Route path="/land-we-love" element={<LandWeLove />} />
          <Route path="/philosockphy" element={<Philosockphy />} />
          <Route path="/ur-thc" element={<UrTHC />} />
          <Route path="/mediadonna" element={<MediaDonna />} />
          <Route path="/take-my-dollar" element={<TakeMyDollar />} />
          <Route path="/pivot-digital" element={<PivotDigital />} />
          <Route path="/ur-cbd" element={<UrCBD />} />
          <Route path="/semi-polished" element={<SemiPolished />} />
          <Route path="/thomson-reuters" element={<ThomsonReuters />} />
          <Route path="/pubguru" element={<PubGuru />} />
          


          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}


function Layout() {
  return ( 
   <>
   <MyNav />
    <Outlet />
   <Footer />
   </>
  );
}

function Home() {
  
  return (
<>
<Container fluid>
  <Row className="header-overlay" xs={1} sm={2} md={2} lg={2}>
    <Col>
    <center><Image src={require('./assets/profile-picture.jpg')} fluid roundedCircle /></center>
    </Col>
    <Col>  
      <h1>Hi, I’m Don</h1>
      <p>I am a seasoned UI/UX designer that has worked with many multinational companies.  Having founded and launched several companies, my strength is my self motivation that being an entrepreneur brings, as well my development chops. </p>
      <Button>Book a call</Button>
    </Col>
  </Row>
  <Row>
    <Companylogos></Companylogos>
  </Row>
  <Row>
    <Col>
    <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="mb-3">
      <Tab eventKey="all" title="All">
        <Row xs={1} sm={2} md={3} lg={3}>
        <WorkItem title="Tim Hortons" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/tim-hortons'></WorkItem>
        <WorkItem title="Crown Capital" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/crown-capital'></WorkItem>
        <WorkItem title="Thomson Reuters" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='thomson-reuters'></WorkItem>
        <WorkItem title="Apex Health Network" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/apex-health-network'></WorkItem>
     

        <WorkItem title="Crown Capital" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/crown-capital'></WorkItem>
        <WorkItem title="Land We Love" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/land-we-love'></WorkItem>
        <WorkItem title="Marlenka" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/marlenka'></WorkItem>
        <WorkItem title="MediaDonna" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/mediadonna'></WorkItem>
        <WorkItem title="Philosockphy" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/philosockphy'></WorkItem>
        <WorkItem title="Pivot Digital" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/pivot-digital'></WorkItem>
        <WorkItem title="PubGuru" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/pubguru'></WorkItem>
        <WorkItem title="SemiPolished" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/semi-polished'></WorkItem>
        <WorkItem title="Take My Dollar" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/take-my-dollar'></WorkItem>
        <WorkItem title="Ur CBD" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/urcbd'></WorkItem>
     
        <WorkItem title="Ur THC" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/urthc'></WorkItem>
     

     
    
        <WorkItem title="SemiPolished" text="sadasdas" img="https://bootstrapious.com/i/snippets/sn-img-hover/hoverSet-3.jpg" linkUrl='/semi-polished'></WorkItem>
     


        
        </Row>
  
      </Tab>
      <Tab eventKey="case studies" title="Case Studies">
     
      <Row>

      </Row>
   
      </Tab>
      <Tab eventKey="wordpress" title="WordPress">
        
      </Tab>
      <Tab eventKey="logos" title="Logos">
        
      </Tab>
      <Tab eventKey="print" title="Print">
        
      </Tab>
     
    </Tabs>
    </Col>
  </Row>
</Container>

</>
  );
}

