import React from 'react';
import { Link } from 'react-router-dom';
import './workitem.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image } from 'react-bootstrap';



const WorkItem = ({ title, text, img, linkUrl }) => (
<>
<Col className='work-item'>
        <Link to={linkUrl}>
          <div class="hover hover-1 text-white"><Image src={img} alt="" responsive />
            <div class="hover-overlay"></div>
            <div class="hover-1-content px-5 py-4">
              <h3 class="hover-1-title text-uppercase font-weight-bold mb-0">{ title }</h3>
              <p class="hover-1-description font-weight-light mb-0">{text}</p>
            </div>
          </div>
          </Link>
          
        </Col>
</>
);

export default WorkItem;
