import React from 'react';
import './companylogos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image, Row, Container } from 'react-bootstrap';

const Companylogos = () => (
<>
<Container className="company-logos" fluid>
       <Row className="text-center">
         <Col>
         <h4 className="body-type">Worked For</h4>
         </Col>
       </Row>
       <Row xs={4} sm={4} md={6} lg={6} align="center" className="justify-content-md-center">
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/48b38c7c-680f-46ef-831f-ef8176669e00/donvvoWorkLogo" fluid/></Col>
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/b5c83c4f-33cd-416a-1624-a97711139900/donvvoWorkLogo" fluid/></Col>
            <Col ><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/313bfbe9-4a78-4712-a6bb-692129493600/donvvoWorkLogo" fluid/></Col>
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/ea96bef9-c558-42e3-f5b1-be1c44409a00/donvvoWorkLogo" fluid/></Col>
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/843f6579-2fc5-401f-e0be-c0598455c200/donvvoWorkLogo" fluid/></Col>
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/5d2cc9dd-026e-4cf1-872c-ffe1bd28be00/donvvoWorkLogo" fluid/></Col>
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/2e4d472d-f5bf-4395-e99d-86a2a5c46d00/donvvoWorkLogo" fluid/></Col>
            <Col><Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/843f6579-2fc5-401f-e0be-c0598455c200/donvvoWorkLogo" fluid/></Col> 
          </Row>
  
</Container>
</>

);

export default Companylogos;
