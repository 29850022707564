import React from 'react';
import { Container, Row, Col, Image, Badge, Carousel } from 'react-bootstrap';

const TimHortons = () => (
  <>
  <Container>
    <Row>
      <Col align="center">
        <h1>CROWN CAPITAL WEBSITE REDESIGN</h1>
        
      </Col>
    </Row>
    <Row>
        <Col>
            <h2>Overview</h2>
            <p>Lead UI/UX Designer</p>
        </Col>
    </Row>
    <Row>
        <Col>
            <h4>my role</h4>
            <p></p>
        </Col>
        <Col>
            <h4>Timeline</h4>
            <p></p>
        </Col>
        <Col>
            <h4>Team members</h4>
            <p>Ralph Sanchez, Simon Lee, Chris Centeno, Mahmud Radjit</p>
        </Col>
        <Col>
            <h4>Tags</h4>
            <div>
            <Badge pill bg="primary">
                Primary
            </Badge>{' '}
            <Badge pill bg="secondary">
                Secondary
            </Badge>{' '}
            <Badge pill bg="success">
                Success
            </Badge>{' '}
            <Badge pill bg="danger">
                Danger
            </Badge>{' '}
            <Badge pill bg="warning" text="dark">
                Warning
            </Badge>{' '}
            <Badge pill bg="info">
                Info
            </Badge>{' '}
            <Badge pill bg="light" text="dark">
                Light
            </Badge>{' '}
            <Badge pill bg="dark">
                Dark
            </Badge>
            </div>
        </Col>
        <Col>
            <h4>Tools</h4>
            <p>Balsamiq, Sketch, InVision

</p>
        </Col>
        
    </Row>
    <Row>
      <Col>
      <h3>Problem</h3>

      <Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/c452b608-bb61-4dd7-2bd7-ac7a33611900/public" fluid />

        <p>
        Crown Capital is a specialty finance company focused on providing capital to middle-market companies that are unwilling or unable to obtain adequate financing from traditional providers.

Given the nature of their business, there are two types of personas that primarily visit Crown Capital:  Retail Investors and companies looking for financing.

Since Crown Capital is a publicly traded company, retail investors visit the site to grasp the company’s focus and performance right off the bat before doing further due diligence.  

Companies seeking financing will also visit their website to get an idea of their specialized industry or financial solution offerings. 

Crown Capital as an entity were also working towards consolidating their services—going from being a broad financier of companies focused on a large volume of deal flow, to being hyper focused on power and networking deals.
        </p>

        <h2>
        RESEARCH
        </h2>

        <h3>Competitive analysis</h3>
        <p>We first looked into the market to examine whether existing specialty finance companies can help middle-market companies obtain adequate financing.</p>
        <p>
        We found that:
        <ul>
            <li>most competitors position themselves as investors first, and partners second</li>
            <li>all other competitors create a strong seperation between power and networking infrastructure portfolios</li>
            <li>Other competitors do not highlight or list specific projects</li>
        </ul>
        </p>

        <h3>Interviews & Affinity Mapping</h3>
        <p>To better understand how retail investors with a focus on infrastructure and power tend to invest and identify potential problems, I advocated for conducting user interviews first before diving into solutions. I also invited our developers to participate in affinity mapping sessions with us, so that as a group, we can brainstorm solutions based on our findings.</p>
        <p>I also advocated to conduct the same process for middle-market companies within the power and networking sector who are interested, currently seeking investment, or have aquired investment in the past to identify potential problems.</p>
        <p>We interviewed around 20 people that are retail investors that have previously or currently invested in the networking or power infrastructure sector.  We found that:  
            <ul>
                <li>environmental sustainability is an important factor for investors

</li>
                <li>Investors must see asset size as well as output of holdings (for example, for power portfolios that would be framed in Mega Watt units)</li>
                <li>positive local community contribution of investments is a big plus</li>
            </ul>
        </p>
        <p>Because of retail investor values, a number of investors choose to stick to broad investments, such as ETFs or indexes.  Most investment management companies, even the larger companies with multiple portfolios on the stock exchange, lacked transparency about the projects or their involvement in said projects.</p>
     
     <h3>Personas</h3>
     <p>I further synthesised the interview results to create the following personas to better guide our design and allow everyone on the team to empathise with our users:</p>

      </Col>
    </Row>
    <Row xs={1} sm={1} md={3} lg={3}>
        <Col>
            <h4>Bob Big Bags</h4>
            <p>10M+ liquid</p>
            <p>VIP Retail Investor, generational wealth or business success</p>
            <p>
                <strong>Needs:</strong>
                <ul>
                    <li>wants to invest in smaller investment management companies where he can have an open line of communication</li>
                    <li>likes the idea of his investments having a positive contribution to his local community </li>
                    <li></li>
                </ul>
            </p>
            <p>
                <strong>Fustrations:</strong>
                <ul>
                    <li>Has to manually dig up what projects make up the portfolio</li>
                    <li>cannot get direct communication or have influence with leadership through larger investment management companies</li>
                    <li>is frustrated with the size and complexity of most management fund sites</li>
                    <li>Takes a long time to dig through quarterly releases to find relevant projects and feels embarassed/overwelmed</li>
                </ul>
            </p>
        </Col>
        <Col>
        <h4>Ryan the Realtor</h4>
            <p>Looking to invest ~10k for his RRSP/TFSA</p>
            <p>Retail investor, lurks on Wall Street Bets</p>
            <p>
            <strong>Needs:</strong>
                <ul>
                    <li>Wants to make sure he is making the right investments with his small portfolio</li>
                    <li>Seeking smaller investment management companies in hopes of a bigger return—needs to see direct project impact or limited partners to feel at ease</li>
                 
                </ul>
            </p>
            <p>
            <strong>Fustrations:</strong>
                <ul>
                    <li>Is embarassed to ask any peers questions, because he is not savvy enough to dig through investor resources to interpret project investments</li>
                    <li>can only make judgement based on leadership’s past performance due to lack of transparency and complexity of most fund sites</li>
                </ul>
            </p>
        </Col>
        <Col>
        <h4>Cindy the Company Owner</h4>
            <p>Owner of a broadband networking or power generation company in Canada</p>
            <p>
            <strong>Needs:</strong>
                <ul>
                    <li>investment for growth, but unable to get funding through traditional means</li>
                    <li>needs injection of management/leadership along with investment</li>
                    <li>needs a company specifically focused on networking or power</li>
                    <li>needs to see common deal structures practiced, as well as deal structures past companies similar to hers has aquired

</li>
                    <li>prefers partnership structures</li>
              
                </ul>
            </p>
            <p>
            <strong>Fustrations:</strong>
                <ul>
                    <li>companies are not transparent about the deal structure formulated with companies invested</li>
                    <li>cannot find information about networking partners or building partners the company has under their portfolio, and therefore cannot determine if she is a good fit from a strategic perspective</li>
                    <li>Is tired of only having access to company mission statements and broad directives, which don’t tell her much about how her company’s fit </li>
                </ul>
            </p>
        </Col>
    </Row>

    <Row>
        <Col>
            <h2>Design</h2>
            <h3>Ideation</h3>
            <p>I invited our engineers and the other designer to collaborate on solutions based on our research findings and personas. I encouraged them to come up with as many ideas as they could without regard for feasibility at this point.</p>
            <p>We then went over the ideas and discussed their viability for implementation. Creating a live Megawatt or portfolio size display that updates based on new projects or investments added, for example, appears to be an excellent value addition; however, the development behind it would be too complex for our developers to implement within the time constraints. As a result, as a first step, we had to make a compromise and provide recommendations based on users' ethnicity.</p>
            <p>Finally, we agreed on the following core functions:

</p>
<h3>SKETCHES & WIREFRAMES</h3>
<p>I quickly sketched out these functionalities with the team to ensure that we were all on the same page.</p>
<p>We created the wireframes below and presented an InVision prototype to our potential users for feedback.</p>

<h3>User feedback & revised design goals
</h3>
<h3>HIGH-FIDELITY DESIGN - ITERATION 1</h3>
<h3>Site map</h3>
<Image src="https://imagedelivery.net/GN-qzJyNXI-JxCQqowY7GQ/58063a1f-d519-42a9-b7d7-e4f90cc3ca00/public" fluid />
<h2>Future Steps</h2>

<h3>Removing page scrolling effect</h3>
<h3>Designing for different languages</h3>
<p>So far, the website only demonstrates the use case of an English visitor. What if the investor or middle-market company is from a country where english is not the primary language? What if these two primary investment segments are known by specific terms in another language? That would probably influence the contextual design of the website. If i were to continue this project, internationalization will be one of the major challenges in the UI Design.</p>

        </Col>
    </Row>
    </Container>
  </>
);

export default TimHortons;



