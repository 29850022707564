import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './mynav.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyNav = () => (
<>
<Navbar collapseOnSelect expand="lg">
  <Container fluid>
  <Navbar.Brand href="#home"><Link to="/">DON VO</Link></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
    
    </Nav>
    <Nav>
      <Nav.Link>
        <Link to="/about">About</Link>
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
</>
);

export default MyNav;
