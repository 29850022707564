import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';
import { Col, Row, Container, InputGroup, FormControl, Button, Accordion } from 'react-bootstrap';
import { ImTwitter, ImInstagram, ImLinkedin } from 'react-icons/im';
import { BsArrowUpRight } from 'react-icons/bs';
import './footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => (
  <footer className="l-footer">
    <div className="l-footer__bottom">



    <Row className="c-footeraccordion">

<Col className="l-footer__column">
  <h2 className="footer__header">about</h2>
  <ul className="footer__list">
    <li className="footer-links__item"><Link to="/faq" className="link--secondary body-type--centi">faq</Link></li>
    <li className="footer__item"><Link to="/privacy-policy">Privacy policy</Link></li>
    <li className="footer__item"><Link to="/terms-of-use">Terms of use</Link></li>
  </ul>
</Col>
<Col className="l-footer__column">
  <h2 className="footer__header">Contact me</h2>
  <ul className="footer__list">
    <li></li>
    <li className="footer__item"><ExternalLink href="https://calendly.com/donvo/30min">Book a meeting <BsArrowUpRight /></ExternalLink></li>

  </ul>
</Col>
<Col className="l-footer__column">
  <h2 className="footer__header">newsletter</h2>
  <ul className="footer__list">
  <li className="footer__item"><ExternalLink href="">Subscribe to our newsletter</ExternalLink></li>
  </ul>
</Col>
<Col className="l-footer__column">
  <h2 className="footer__header">Connect</h2>
  <ul className="footer__list">
    <li className="footer__item"><ExternalLink href="https://twitter.com/donvvo1">Twitter</ExternalLink></li>
    <li className="footer__item"><ExternalLink href="https://instagram.com/donvvo">Instagram</ExternalLink></li>
    <li className="footer__item"><ExternalLink href="https://www.linkedin.com/in/donvvo/">LinkedIn</ExternalLink></li>
  </ul>
</Col>
</Row>


<Row className="l-footer__copyright">
<Col><span>© 2022 Don Vo</span></Col>
</Row>




    </div>
</footer>
);

export default Footer;



                        
                          
                     